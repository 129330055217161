export const TX_LOADING = 'TX_LOADING';
export const TX_FAILED = 'TX_FAILED';
export const TX_SUCCESS = 'TX_SUCCESS';

/* *~~*~~*~~*~~*~~* WALLET REDUCER *~~*~~*~~*~~*~~* */

export const REQUEST_CONNECTION = 'REQUEST_CONNECTION';
export const SET_NETWORK_ID = 'SET_NETWORK_ID';
export const SET_METAMASK_INSTALLED = 'SET_METAMASK_INSTALLED';
export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';
export const SET_CONNECTION = 'SET_CONNECTION';
export const SET_CONNECTED_TO_OPERATING_NETWORK = 'SET_CONNECTED_TO_OPERATING_NETWORK';

export const CHANGE_FORM_STATE = 'CHANGE_FORM_STATE';
export const form_states = {
    ERROR: 'ERROR',
    APPROVE: 'APPROVE',
    SWITCH_TO_CHAIN: 'SWITCH_TO_CHAIN',
    SEND_TX: 'SEND_TX',
};

export const SET_TOKEN_APPROVED = 'SET_TOKEN_APPROVED';
export const SET_CHAINS_MATCH = 'SET_CHAINS_MATCH';
export const SET_CUSTOM_TOKEN_SUPPORTED = 'SET_CUSTOM_TOKEN_SUPPORTED';
export const SET_TOKEN_IS_L2 = 'SET_TOKEN_IS_L2';

export const ADD_USER_TOKEN = 'ADD_USER_TOKEN';
export const REMOVE_USER_TOKEN = 'REMOVE_USER_TOKEN';

export const SET_SECTION = 'SET_SECTION';

export const SET_MEMBER = 'SET_MEMBER';

/* *~~*~~*~~*~~*~~* MODAL REDUCER *~~*~~*~~*~~*~~* */

export const OPEN_MODAL = 'OPEN_MODAL';
export const PLAY_CLOSE_ANIMATION = 'PLAY_CLOSE_ANIMATION';
export const CLOSE_MODAL = 'CLOSE_MODAL';

/* *~~*~~*~~*~~*~~* EPISODE 3 MINT REDUCER *~~*~~*~~*~~*~~* */

export const FETCH_DATA_TRAF_SUCCESS = 'FETCH_DATA_TRAF_SUCCESS';
export const FETCH_DATA_TRAF_FAILED = 'FETCH_DATA_TRAF_FAILED';
export const FETCH_DATA_TRAF_REQUEST = 'FETCH_DATA_TRAF_REQUEST';

/* *~~*~~*~~*~~*~~* HOLDER REDUCER *~~*~~*~~*~~*~~* */

export const PRIMEHOLDER_ELIGIBLE_GET_SUCCESS = 'PRIMEHOLDER_ELIGIBLE_GET_SUCCESS';

export const PRIMEHOLDER_ELIGIBLE_GET_FAILED = 'PRIMEHOLDER_ELIGIBLE_GET_FAILED';

export const PRIMEHOLDER_ELIGIBLE_GET_REQUEST = 'PRIMEHOLDER_ELIGIBLE_GET_REQUEST';

export const PARTNERHOLDER_ELIGIBLE_GET_SUCCESS = 'PARTNERHOLDER_ELIGIBLE_GET_SUCCESS';

export const PARTNERHOLDER_ELIGIBLE_GET_FAILED = 'PARTNERHOLDER_ELIGIBLE_GET_FAILED';

export const PARTNERHOLDER_ELIGIBLE_GET_REQUEST = 'PARTNERHOLDER_ELIGIBLE_GET_REQUEST';

export const GENERALHOLDER_ELIGIBLE_GET_SUCCESS = 'GENERALHOLDER_ELIGIBLE_GET_SUCCESS';

export const GENERALHOLDER_ELIGIBLE_GET_FAILED = 'GENERALHOLDER_ELIGIBLE_GET_FAILED';

export const GENERALHOLDER_ELIGIBLE_GET_REQUEST = 'GENERALHOLDER_ELIGIBLE_GET_REQUEST';

export const SET_HEELS_REQUEST = 'SET_HEELS_REQUEST';
export const SET_HEELS_SUCCESS = 'SET_HEELS_SUCCESS';
export const SET_HEELS_FAILED = 'SET_HEELS_FAILED';
